.chevronButtonInPentagon {
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  height: 4rem;
  padding: 0;
  position: relative;
  width: 4rem;

  &.small {
    height: 3.6rem;
    width: 3.6rem;
  }

  &.pointLeft {
    transform: rotate(180deg);
  }

  & svg {
    left: 0;
    position: absolute;
    top: 0;

    &:first-child {
      width: 100%;
      height: 100%;
    }

    &:last-child {
      height: 80%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      width: 80%;
    }
  }
}
