.container {
  margin: 0 auto;
  max-width: var(--container-l);
  padding: 0 var(--max-width-padding-s);
  width: 100%;

  & .container {
    padding: 0;
  }

  @media (--viewport-m) {
    padding: 0 var(--max-width-padding-m);
  }

  &.small {
    max-width: var(--container-s);
  }

  &.medium {
    max-width: var(--container-m);
  }

  &.mediumLarge {
    max-width: var(--container-ml);
  }

  &.extraLarge {
    max-width: var(--container-xl);
  }

  &.noMobilePadding {
    padding: 0;

    @media (--viewport-l) {
      padding: 0 3.2rem;
    }
  }

  &.noPadding {
    padding: 0;

    @media (--viewport-l) {
      padding: 0;
    }
  }

  &.hasMarginOverflow:not(.noMarginOverflow) {
    --overflowing-container-size: var(--container-l);

    /* This css will place the content to the right side of the screen.
    -- Only when the element has .hasMarginOverflow class and has NOT the overrriding .noMarginOverflow class.
    -- It's only applied until 145em screen size. After that, we will center the content again.
    -- The max-width is the current max with, plus the margin that should be rendered on the right side.
    -- Calculated by (100vw - 100%) / 2 */

    @media screen and (width <= 145em) {
      margin-left: auto;
      margin-right: 0;
      max-width: calc(var(--overflowing-container-size) + ((100vw - var(--overflowing-container-size)) / 2));
    }

    &.small {
      --overflowing-container-size: var(--container-s);
    }

    &.medium {
      --overflowing-container-size: var(--container-m);
    }

    &.extraLarge {
      --overflowing-container-size: var(--container-xl);
    }
  }
}
