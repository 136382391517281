.globalButtonContainer {
  display: flex;
  align-items: center;
  gap: 0.4em;
  white-space: normal;

  & svg {
    height: var(--font-size-m);
  }
}

.sharedStyles {
  --button-height: 3.8rem; /* make sure this is same or a little bigger than font-size + padding */

  background: var(--color-transparent);
  border: 0;
  color: var(--color-midnight-800);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-xs);
  min-height: var(--button-height);
  padding: 0;
  position: relative;
  text-decoration: none;
  white-space: nowrap;

  &:disabled {
    & > .inner,
    &:hover > .inner {
      background-color: var(--color-white);
      color: var(--color-rolling-stone-200);
    }

    & > svg,
    &:hover > svg {
      fill: var(--color-white);
      stroke: var(--color-white);
    }
  }

  &.reverse {
    transform: scale(-1, 1);

    & .inner {
      transform: scale(-1, 1);
      border-radius: 0 var(--border-radius) var(--border-radius) 0;
      padding: 0.8rem 2.4rem 0.8rem 0.8rem;
    }
  }

  /* TODO: fix vertical alignment of button icons */
  & .inner {
    align-items: center;
    border: 1px solid transparent;
    background-color: var(--color-riptide-300);
    border-radius: var(--border-radius) 0 0 var(--border-radius);
    display: flex;
    min-height: var(--button-height);
    padding: 0.8rem 0.8rem 0.8rem 2.4rem;
    text-align: center;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    position: relative;

    @media (--viewport-m) {
      text-align: left;
    }

    & svg {
      fill: currentcolor;
      stroke: currentcolor;
    }
  }

  & > svg {
    display: block;
    fill: var(--color-riptide-300);
    flex-shrink: 0;
    height: var(--button-height);
    stroke: var(--color-riptide-300);
    transition: fill 0.2s ease-in-out, stroke 0.2s ease-in-out;
    width: auto;
    left: -0.2rem;
    position: relative;
  }

  &:hover .inner,
  &:focus .inner {
    background-color: var(--color-riptide-500);
  }

  &:hover > svg,
  &:focus > svg {
    fill: var(--color-riptide-500);
    stroke: var(--color-riptide-500);
  }

  &.outline {
    --line-color: var(--color-midnight-900);
    --line-color-hover: var(--color-midnight-900);
    --background-normal: var(--color-white);
    --background-hover: var(--color-white);
    
    /* no hover states needed on mobile */
    @media (--viewport-menu) {
      --line-color-hover: var(--color-midnight-700);
    }

    & .inner {
      border: var(--line-color) 0.1rem solid;
      background: var(--background-normal);
      border-right: none;
      text-decoration: none;
      color: var(--line-color);

      & svg {
        fill: currentcolor;
        stroke: currentcolor;
      }
    }

    & > svg {
      fill: var(--background-normal);
      stroke: var(--line-color);
    }

    &:hover, &:focus {
      & .inner {
        border-color: var(--line-color-hover);
        color: var(--line-color-hover);
        background: var(--background-hover);
      }
  
      & > svg {
        fill: var(--background-hover);
        stroke: var(--line-color-hover);
      }
    }
  }

  &.outlineInverted {
    --line-color: var(--color-white);
    --line-color-hover: var(--color-white);
    --background-normal: var(--color-midnight-900);
    --background-hover: var(--color-midnight-900);
     
    /* no hover states needed on mobile */
    @media (--viewport-menu) {
      --line-color-hover: var(--color-rolling-stone-200);
    }

    color: var(--line-color);

    & .inner {
      border:  var(--line-color) 0.1rem solid;
      background: var(--background-normal);
      border-right: none;
      text-decoration: none;

      & svg {
        fill: currentcolor;
        stroke: currentcolor;
      }
    }

    & > svg {
      fill: var(--background-normal);
      stroke: var(--line-color);
    }

    &:hover, &:focus {
      & .inner {
        background: var(--background-hover);
        color: var(--line-color-hover)
      }

      & > svg {
        fill: var(--background-hover);
        stroke: var(--color-white);
      }
    }
  }

  &.square {
    & .inner {
      border-radius: var(--border-radius);
      padding: 0.8rem 2.4rem;
    }
  }

  &.basicRectangle {
    --button-height: calc(5.2rem - 0.4rem);

    padding: 0.2rem 1.6rem;
    gap: 1.2rem;
    background: var(--button-basic-rectangle-surface, var(--color-midnight-900));
    color: var(--button-basic-rectangle-color);
    align-items: center;
    border-radius: var(--button-basic-border-radius, var(--border-radius-l));
    transition: background 0.2s ease-in-out;
    font-weight: var(--button-basic-font-weight, var(--font-weight-bold));
    

    &.reverse > * {
      transform: scale(-1, 1);
    }

    &.multiRow {
      padding: 1rem 1.6rem;
      white-space: normal;
    }

    &.backLinkButton {
      max-width: 90%;

      @media (--viewport-m) {
        max-width: 65%;
      }
    }

    & > svg {
      width: 2rem;
      height: 2rem;
      fill: currentcolor;
      stroke: currentcolor;
    }

    &:hover,
    &:focus {
      background: var(--button-basic-rectangle-surface-hover, var(--color-midnight-800));
    }

    &.outline {
      border: var(--button-basic-rectangle-surface, var(--color-midnight-900)) 0.1rem solid;
      background: transparent;
      border-right: none;
      text-decoration: none;
      color: var(--button-basic-rectangle-surface, var(--color-midnight-900));

      & svg {
        fill: transparent;
        stroke: var(--button-basic-rectangle-surface, var(--color-midnight-900));
      }

      &:hover .inner,
      &:focus .inner {
        background: transparent;
        text-decoration: underline;
      }
    }

    &.transparent {
      background: transparent;
      color: var(--button-basic-rectangle-surface, var(--color-midnight-900));

      & svg {
        fill: transparent;
        stroke: var(--button-basic-rectangle-surface, var(--color-midnight-900));
      }

      &:hover,
      &:focus {
        background: transparent;
      }
    }

    &.transparentWhite {
      background: #d8d8d854;
      color: var(--color-white);

      & svg {
        fill: var(--color-white);
        stroke: transparent;
      }

      &:hover,
      &:focus {
        background: #ffffff54;
      }
    }

  }
}

/* --------------------------------------------VAN DE KLOK----------------------------------------------------------- */

.vanDeKlok {
  color: var(--color-white);

  & .inner {
    background-color: var(--color-midnight-900);
    color: var(--color-white);
  }

  & > svg {
    fill: var(--color-midnight-900);
    stroke: var(--color-midnight-900);
  }

  &:hover .inner,
  &:focus .inner {
    background-color: var(--color-tangerine-600);
  }

  &:hover svg,
  &:focus svg {
    fill: var(--color-tangerine-600);
    stroke: var(--color-tangerine-600);
  }

  &.orange {
    & .inner {
      background-color: var(--color-tangerine-600);
    }

    & > svg {
      fill: var(--color-tangerine-600);
      stroke: var(--color-tangerine-600);
    }

    &:hover .inner,
    &:focus .inner {
      background-color: var(--color-midnight-900);
    }

    &:hover svg,
    &:focus svg {
      fill: var(--color-midnight-900);
      stroke: var(--color-midnight-900);
    }
  }

  &.outline {
    --line-color: var(--color-midnight-800);
    --background-normal: transparent;
    --background-hover: transparent;
  }
}

/* --------------------------------------------NOVAFORM----------------------------------------------------------- */

.novaform {
  color: var(--color-white);

  & .inner {
    background-color: var(--color-persian-red-600);
    color: var(--color-white);
  }

  & > svg {
    fill: var(--color-persian-red-600);
    stroke: var(--color-persian-red-600);
  }

  &:hover .inner,
  &:focus .inner {
    color: var(--color-white);
    background-color: var(--color-persian-red-700);
  }

  &:hover svg,
  &:focus svg {
    fill: var(--color-persian-red-700);
    stroke: var(--color-persian-red-700);
  }

  &.redText {
    color: var(--color-persian-red-600);
    display: flex;
    align-items: center;
    font-weight: var(--font-weight-regular);

    & .inner {
      background: none;
      color: var(--color-persian-red-600);
      padding-left: 0;
    }

    & > svg {
      fill: var(--color-persian-red-600);
      stroke: var(--color-persian-red-600);
      transform: rotate(180deg);
      transition: margin-left 0.2s ease, transform 0.2s ease;
      stroke-width: 0;
      height: 2rem;
    }

    &:hover svg,
    &:focus svg {
      margin-left: 0.5rem;
      transform: rotate(180deg);
    }
  }

  &.outline {
    --line-color: var(--color-white);
    --line-color-hover: var(--color-white);
    --background-normal: transparent;
    --background-hover: transparent;

    & .inner {
      border: none;     
      text-decoration: none;
      padding-right: 0;
      padding-left: 1rem;

      & svg {
        fill: var(--color-white) ;
        stroke: var(--color-white);
      }
    }

    & > svg {
      display: none;
    }
  }

  &.outlineInverted {
    color: var(--color-white);
    text-decoration: none;

    & .inner {
      border: none;
    }

    &:hover .inner,
    &:focus .inner {
      background: transparent;
      text-decoration: underline;
      color: var(--color-rolling-stone-200);
    }

    &:hover svg,
    &:focus svg {
      display: none;
    }
  }

  &.outlineDark {
    & .inner {
      border: none;
      background: transparent;
      color: var(--color-rolling-stone-800);
      text-decoration: none;

      & svg {
        fill: var(--color-rolling-stone-800); ;
        stroke: var(--color-rolling-stone-800);;
      }
    }

    &:hover .inner,
    &:focus .inner {
      background: transparent;
      color: var(--color-rolling-stone-600);
    }

    & > svg {
      display: none;
      fill: transparent;
      stroke: transparent;
    }
  }
}

/* --------------------------------------------HEILIJGERS----------------------------------------------------------- */

.heilijgers {
  color: var(--color-white);

  & .inner {
    background-color: var(--color-fruit-salad-500);
    color: var(--color-white);
  }

  & > svg {
    fill: var(--color-fruit-salad-500);
    stroke: var(--color-fruit-salad-500);
  }

  &:hover .inner,
  &:focus .inner {
    background-color: var(--color-fruit-salad-500);
    color: var(--color-rolling-stone-800);
  }

  &:hover svg,
  &:focus svg {
    fill: var(--color-fruit-salad-500);
    stroke: var(--color-fruit-salad-500);
  }

  &.outline {
    --line-color: var(--color-rolling-stone-900);
    --background-normal: transparent;
    --background-hover: transparent;
  }

  &.blackText {
    color: var(--color-rolling-stone-800);

    & .inner {
      background: none;
    }

    & > svg {
      fill: transparent;
      stroke: transparent;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

/* --------------------------------------------VAN-ERK----------------------------------------------------------- */

.vanErk {
  color: var(--color-white);

  & .inner {
    background-color: var(--color-midnight-500);
    color: var(--color-white);
  }

  & > svg {
    fill: var(--color-midnight-500);
    stroke: var(--color-midnight-500);
  }

  &:hover .inner,
  &:focus .inner {
    color: var(--color-midnight-800);
    background-color: var(--color-midnight-500);
  }

  &:hover > svg,
  &:focus > svg {
    fill: var(--color-midnight-500);
    stroke: var(--color-midnight-500);
  }

  &.outline {
    --line-color: var(--color-midnight-800);
    --background-normal: transparent;
    --background-hover: transparent;
  }
}
