.container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
}

.description {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 0 0 0.5em;
  color: var(--inputcontainer-description-color, inherit);
  font-weight: var(--inputcontainer-description-font-weight, var(--font-weight-regular));
}

.error {
  color: var(--inputcontainer-error-color, var(--color-pomegranate-300));
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-m);
  margin: 0.8rem 0 0;
}

.childContainer {
  position: relative;
}
