.container {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: var(--default-picture-sourceset-border-radius);

  &.square {
    aspect-ratio: 1;
  }

  &.portrait {
    aspect-ratio: 3/4;
  }

  &.landscape {
    aspect-ratio: 16/10;
  }

  & img {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
  }
}
