.form {
  display: flex;
  flex-direction: var(--newsletter-form-flex-direction, column);
  gap: 1.2rem;
  width: 100%;

  & > button {
    flex-shrink: 0;
  }
}

.input {
  background: transparent;
  border-bottom: solid 0.1rem var(--newsletter-form-input-border-color, var(--color-white));
  border-left: 0;
  border-right: 0;
  border-top: 0;
  color: var(--newsletter-form-input-color, var(--color-white));
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-m);
  outline: none;
  padding: 0 0 0.4rem;
  width: 100%;
  
  @media (--viewport-m) {
    font-size: var(--font-size-m);
  }

  @media (--viewport-l) {
    font-size: var(--font-size-l);
  }

  &::placeholder {
    color: var(--newsletter-form-input-placeholder-color, var(--color-rolling-stone-400));
  }

  &:focus {
    border-bottom-color: var(--newsletter-form-input-border-hover-color, var(--color-riptide-300));
  }
}

.error {
  color: var(--newsletter-form-error-color, var(--color-pomegranate-300));
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-m);
  margin: 0.8rem 0 0;
}
