.inputsContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin: 2rem 0;
}

.buttonContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.outlineButtonContainer {
  & button {
    color: var(--cookiemodal-outline-button-color, inherit);

    &[class*="novaform"] {
      & > [class*='inner'] {
        padding-left:0;
      }
    }
  }
}

.input {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-m);
  gap: 0.4rem;
  line-height: var(--line-height-s);
}

.banner {
  align-items: center;
  background-color: var(--color-white);
  border-top: 0.1rem solid var(--color-midnight-800);
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  justify-content: space-between;
  left: 0;
  padding: 1.6rem;
  position: fixed;
  right: 0;
  z-index: var(--z-index-cookie-banner);

  @media (--viewport-m) {
    flex-direction: row;
  }

  & .content {
    flex: 1;
  }

  & .buttonContainer {
    display: flex;
    flex-direction: row;
  }
}
