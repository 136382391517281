.contactPerson {
  display: none;
  gap: 1.2rem;
  position: absolute;
  border-radius: var(--border-radius-l);
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  right: 0;
  height: calc(var(--siteheader-height-l) - 2.4rem);
  background: var(--color-white);
  color: var(--body-text-color);
  border: 1px solid var(--contactperson-header-border-color, var(--color-rolling-stone-100));
  transform: translateY(calc(-100% - 1.2rem));
  transition: transform 0.2s ease-out;

  & > *:not(.info) {
    flex: 0 0 auto;
    width: auto;
    height: 100%;
    aspect-ratio: 1;
    border: 1px solid var(--contactperson-header-border-color, var(--color-rolling-stone-050));
  }

  @media (--viewport-menu) {
    display: flex;
  }

  &.isVisible {
    transform: translateY(0);
    transition: transform 0.6s cubic-bezier(0.4, 0.36, 0, 0.98);
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 0;
  flex: 1 0 auto;
  align-items: flex-end;
  justify-content: center;
  font-size: var(--font-size-s);
}

.button {
  align-items: center;
  background: none;
  border: none;
  color: var(--contactperson-header-link-color, var(--color-midnight-900));
  cursor: pointer;
  display: flex;
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  text-decoration: underline;
  padding: 0;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.headerTitle {
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  line-height: 1.1;
  max-width: 32rem;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  text-align: right;
  -webkit-box-orient: vertical;
}
