:root[site-id="vanerk"],
:root[site-id="vanerk-timmerfabriek"] {
  --body-background-color: #fff;
  --default-border-radius: 0.4rem;
  --default-picture-sourceset-border-radius: var(--default-border-radius);
  --header-box-shadow: none;
  --heading-weight: 600;
  --heading-weight-strong: 600;
  --homepage-hero-background-color: var(--body-background-color);
  --button-basic-rectangle-surface: var(--color-midnight-500);
  --button-basic-rectangle-surface-hover: var(--color-midnight-600);
  --button-basic-rectangle-color: #fff;
  --button-basic-border-radius: 0.4rem;
  --block-container-background-color-default: var(--body-background-color);
  --block-container-background-color-blue: var(--color-midnight-900);
  --block-container-surface-color-blue: var(--color-white);
  --block-container-background-color-white: var(--color-white);
  --backbutton-icon-color: var(--color-midnight-500);
  --sharefooter-icon-hover-color: var(--color-midnight-500);
  --job-item-container-background-color: var(--color-rolling-stone-050);
  --job-item-container-border-radius: 0.4rem;
  --filter-container-background-color: var(--color-white);
  --range-slider-inner-rail-background: var(--color-midnight-500);
  --range-slider-control-background: var(--color-midnight-600);
  --siteheader-navigation-background-color: var(--color-midnight-500);
  --header-background-color-active: var(--color-midnight-500);
  --siteheader-navigation-item-color: var(--color-midnight-800);
  --siteheader-menu-item-hover-background-color: var(--color-rolling-stone-050);
  --siteheader-navigation-item-font-weight: var(--font-weight-bold);
  --siteheader-submenu-background-color: var(--color-midnight-500);
  --siteheader-submenu-color: var(--color-midnight-800);
  --siteheader-backbutton-color: var(--color-rolling-stone-100);
  --siteheader-navigation-item-backbutton-icon-color: var(--color-rolling-stone-050);
  --siteheader-arrow-right-color: var(--color-midnight-800);
  --background-color-job-item-container: var(--color-rolling-stone-050);
  --background-control-range-slider: var(--color-midnight-600);
  --background-inner-rail-range-slider: var(--color-midnight-500);
  --backround-color-filter-container: var(--color-white);
  --image-badge-hover-background-color: var(--color-midnight-500);
  --image-badge-hover-color: var(--color-white);
  --video-playbutton-background-color: var(--color-midnight-500);
  --video-playbutton-border-rgb: 61 151 219;
  --contentcard-subtitle-color: var(--color-midnight-500);
  --contentcard-subtitle-font-weight: 600;
  --contentcard-innerbox-background-color: var(--color-rolling-stone-050);
  --contentcard-innerbox-surface: var(--body-text-color);
  --sitefooter-social-icons-border-radius: var(--border-radius-l);
  --sitefooter-social-icons-path-fill-color: var(--color-midnight-500);
  --sitefooter-title-color: var(--color-midnight-800);
  --footer-link-color: var(--color-midnight-800);
  --newsletter-subscription-tile-container-background-color: var(--color-midnight-800);
  --newsletter-form-input-placeholder-color: var(--color-rolling-stone-200);
  --newsletter-form-error-color: var(--color-pomegranate-500);
  --newsletter-form-input-border-hover-color: var(--color-midnight-500);
  --contact-card-text-color: var(--color-white);
  --contact-card-icon-color: var(--contact-card-text-color);
  --contact-card-background-color: var(--color-midnight-500);
  --input-border-radius: var(--border-radius-l);
  --input-focus-border-color: var(--color-midnight-500);
  --modal-content-background-color: var(--color-midnight-800);
  --modal-content-color: var(--color-white);
  --inputcontainer-description-font-weight: var(--font-weight-bold);
  --contactform-accept-conditions-color: var(--modal-content-color);
  --modal-header-icon-color: var(--color-white);
  --preview-bar-background-color: var(--color-midnight-500);
  --contact-page-body-background-color: var(--color-rolling-stone-050);
  --hero-border-radius: var(--border-radius-l);
  --newsletter-form-input-border-color: var(--color-rolling-stone-200);
  --newsletter-form-input-color: var(--newsletter-form-input-border-hover-color);
  --contactperson-header-link-color: var(--color-midnight-600);
  --call-to-action-content-color: var(--body-text-color);
  --vertical-carousel-button-color-dark: var(--color-midnight-800);
  --richtext-quote-bar-color: var(--color-midnight-500);
  --richtext-link-color-hover: var(--color-midnight-500);
  --page-preview-item-location-color: var(--color-midnight-500);
  --linklist-chevron-color: var(--color-midnight-800);
  --newsletter-additional-data-form-color: var(--color-white);
  --newsletter-additional-data-popup-cointainer-color: var(--color-white);
  --block-container-surface-link-color-blue: var(--color-midnight-500);
  --block-container-surface-link-color: var(--color-midnight-500);
}
