.container {
  display: flex;
  gap: 1.2rem;
}

.switch {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 2.4rem;
}

.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: var(--color-rolling-stone-200);
  border-radius: 1.2rem;
  transition: 0.4s;

  &::before {
    position: absolute;
    content: "";
    height: 1.6rem;
    width: 1.6rem;
    left: 0.3rem;
    bottom: 0.4rem;
    background-color: var(--color-white);
    border-radius: 50%;
    transition: 0.4s;
  }

  /* @nest input:checked + & {
    background-color: var(--color-riptide-400);
  } */
}

input:checked + .slider::before {
  transform: translateX(1.8rem);
}
