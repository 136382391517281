.container {
  bottom: 0;
  left: 0;
  padding: 2.4rem 0;
  position: fixed;
  width: 100%;
  z-index: var(--z-index-site-header);
}

.containerActive {
  background-color: var(--preview-bar-background-color, var(--color-riptide-500));
  bottom: 0;
  left: 0;
  padding: 2.4rem 0;
  position: fixed;
  width: 100%;
  z-index: var(--z-index-site-header);
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;
  margin-bottom: 0.8rem;

  & > :last-child {
    flex-shrink: 0;
  }
}
