.logo {
  display: block;

  & svg {
    height: auto;
    width: 10rem;

    @media (--viewport-l) {
      width: 22rem;
    }
  }

  &.menuIsActive {
    & svg {
      stroke: var(--siteheader-logo-color-active);

      & path {
        fill: var(--siteheader-logo-color-active);
      }
    }
  }
}

.container {
  background-color: var(--header-background-color);
  box-shadow: var(--header-box-shadow);
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  top: 0;
  gap: 0.8rem;
  padding: 1.2rem 0;
  position: fixed;
  transition: top 200ms ease-out, transform 200ms ease-out, color 200ms ease-out, background-color 200ms ease-out;
  width: 100%;
  z-index: var(--z-index-site-header);

  @media (--viewport-menu) {
    padding: 0.5rem 0;
    min-height: var(--siteheader-height-l);
  }

  &.hasVisibleSearchBar {
    top: var(--searchbar-height-s);
    transform: none;

    @media (--viewport-menu) {
      top: 0;
      transform: translateY(var(--searchbar-height-l));
    }
  }

  &.containerActive {
    background-color: var(--header-background-color-active);

    & .logo svg {
      fill: var(--color-white);
    }
  }
}

.containerInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.logoAndNavContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: row;

  @media (--viewport-l) {
    align-items: center;
    gap: 2.4rem;

    & > *:first-child {
      padding-right: var(--siteheader-menu-items-padding);
    }
  }
}

/* Mobile menu only styling */
@media (--viewport-menu-max) {
  .navigation {
    background-color: var(--siteheader-navigation-background-color, var(--color-midnight-900));
    bottom: 0;
    color: var(--color-white);
    display: block;
    left: 0;
    padding: 1.2rem 1.6rem;
    position: fixed;
    top: var(--siteheader-height-s);
    transform: translateX(-100%);
    transition: transform 200ms ease-out;
    width: 100%;
    z-index: 1;

    &.navigationOpen {
      transform: translateX(0%);
    }
  }

  /* stylelint-disable-line */
  .hasVisibleSearchBar .navigation {
    transform: translate(-100%, var(--searchbar-height-s));

    &.navigationOpen {
      transform: translate(0%, var(--searchbar-height-s));
    }
  }

  .mainNavigation {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;

    & .nestedSubmenuButton {
      /* stylelint-disable-next-line max-line-length */
      background-color: var(--siteheader-open-submenu-button-background-color, var(--siteheader-navigation-background-color));
      border: none;
    }
  }

  .navigationItem {
    color: var(--siteheader-submenu-color, var(--color-white));
    display: flex;
    font-size: var(--font-size-3xl);
    justify-content: space-between;
    line-height: var(--line-height-s);
    padding: 0.8rem 0;
    font-weight: var(--siteheader-navigation-item-font-weight, var(--font-weight-regular));

    /* padding: 1.6rem 0; @todo: Uitzoeken welke padding die wannneer nodig heeft */
    position: relative;
    text-decoration: none;
    width: 100%;
    min-height: 3.6rem;

    &::after {
      background: var(--color-riptide-300);
      bottom: 0;
      content: "";
      height: 0.1rem;
      left: 0;
      position: absolute;
      transition: width 200ms ease-out;
      width: 0%;
    }

    &.dropdownItem svg {
      display: block;
    }
  }

  .menuButtonContainer {
    display: block;

    /* overwrite the ouline color of the menu button */
    & button span {
      border-color: var(--siteheader-navigation-item-color) !important;
      color: var(--siteheader-navigation-item-color) !important;
    }

    & button svg {
      &:last-child {
        stroke: var(--siteheader-menu-button-svg-stroke-color, var(--siteheader-navigation-item-color)) !important;
      }

      &:first-child {
        stroke: var(--siteheader-navigation-item-color) !important;

        & path {
          fill: var(--siteheader-navigation-item-color) !important;
        }
      }
    }

    & span {
      text-decoration: none !important;
    }
  }

  .submenu {
    background-color: var(--siteheader-submenu-background-color);
    color: var(--siteheader-submenu-color, var(--color-white));
    display: flex;
    flex-direction: column;
    gap: 0;
    list-style-type: none;
    margin: 0;
    padding: 1.2rem 1.6rem;
    position: fixed;
    inset: 0;
    transform: translateX(-100%);
    transition: transform 200ms ease-out;
    z-index: 2;

    &.open {
      transform: translateX(0);
    }
  }

  .backButton button {
    display: flex;
    background: none;
    border: none;
    color: var(--siteheader-backbutton-color, var(--color-white));
    padding: 0.8rem;
    align-items: center;
    gap: 0.8rem;
    font-size: var(--font-size-m);

    & svg {
      transform: rotate(180deg);
      width: 1.3rem;
      stroke: var(--siteheader-navigation-item-backbutton-icon-color, var(--siteheader-navigation-item-color));

      & path {
        fill: var(--siteheader-navigation-item-backbutton-icon-color, var(--siteheader-navigation-item-color));
      }
    }
  }

  .openSubmenuButton {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;

    & svg {
      height: 2rem;
      width: 1.3rem;
    }
  }

  .menuItem {
    padding: 0.8rem;

    &:hover,
    &:focus {
      & svg {
        margin-right: 0.8rem;
      }
    }

    & a {
      display: flex;
      align-items: baseline;
    }
  }

  .arrowDown {
    display: none;
  }

  .arrowRight svg {
    width: 1.3rem;
    stroke: var(--siteheader-arrow-right-color, var(--siteheader-navigation-item-color));

    & path {
      fill: var(--siteheader-arrow-right-color, var(--siteheader-navigation-item-color));
    }
  }

  .menuButtonsContainer {
    & .searchToggleButton {
      opacity: 0;
      pointer-events: none;
      transition: opacity 100ms ease-out;
    }
  }

  .containerActive {
    & .searchToggleButton {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.menuButtonsContainer {
  display: flex;
  align-items: center;
  gap: 1rem;

  & .searchToggleButton {
    appearance: none;
    border: 0;
    color: var(--siteheader-navigation-item-color);
    background: transparent;
    width: 3.8rem;
    height: 3.8rem;
    display: flex;
    align-items: center;
    padding: 0.8rem;

    & svg {
      height: 100%;
    }
  }
}

/* Desktop menu only styling */
@media (--viewport-menu) {
  .arrowRight,
  .backButton {
    display: none;
  }

  .menuButtonsContainer {
    & .searchToggleButton {
      cursor: pointer;
      width: 2.4rem;
      height: 2.4rem;
      padding: 0.2rem;
    }

    & .mobileNavigationMenuToggle {
      display: none;
    }
  }

  .arrowDown {
    width: 1.3rem;
    margin-left: 0.6rem;
    stroke: var(--siteheader-navigation-item-color);

    & path {
      fill: var(--siteheader-navigation-item-color);
    }
  }

  .mainNavigation {
    position: relative;
    display: flex;
    padding: 0;
    list-style: none;
    margin: 0;
    gap: var(--siteheader-navigation-items-gap, 1.2rem);

    & > .menuItem {
      padding: 0 1.6rem;
      position: relative;
    }
  }

  .submenu {
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style: none;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms ease-out;
    transition-delay: 100ms;

    &.open {
      opacity: 1;
      pointer-events: all;
    }
  }

  .submenuLevel1 {
    padding: 0.8rem 0;
    min-width: 24rem;
    background: var(--siteheader-submenu-level1-background-color, var(--color-white));
    border-radius: var(--border-radius);
    box-shadow: 0 0 0.6rem -0.1rem rgb(0 0 0 / 10%), 0 0 0.4rem -0.1rem rgb(0 0 0 / 6%);

    &.hideBoxShadow {
      box-shadow: none;
    }

    & .arrowRight {
      display: block;
      transition: transform 250ms ease-in-out;

      & svg {
        width: 0.8rem;
        stroke: var(--siteheader-navigation-item-color);

        & path {
          fill: var(--siteheader-navigation-item-color);
        }
      }
    }

    & .arrowDown {
      display: none;
    }

    & .menuItemWithLink {
      padding: 0.8rem;
      position: relative;
    }

    & > .menuItem > .menuItemWithLink {
      padding: 0.8rem 1.6rem;

      &::before {
        content: "";
        opacity: 0;
        position: absolute;
        inset: 0 0.8rem;
        z-index: -1;
        background-color: var(--color-rolling-stone-050);
        border-radius: var(--border-radius);
      }

      &:hover {
        &::before {
          opacity: 1;
          background-color: var(--siteheader-menu-item-hover-background-color, var(--color-rolling-stone-050));
        }
      }

      &::after {
        display: none;
      }
    }
  }

  .submenuLevel2 {
    padding: 0.8rem 0;
    background: var(--siteheader-submenu-level2-background-color, var(--color-white));
    padding-left: 24rem;
    top: 0;
    min-width: 48rem;
    z-index: -1;
    min-height: 100%;
    box-shadow: 0 0 0.6rem -0.1rem rgb(0 0 0 / 10%), 0 0 0.4rem -0.1rem rgb(0 0 0 / 6%);
    border-radius: var(--border-radius);

    &::after {
      border-radius: var(--siteheader-open-submenu-button-border-radius, var(--border-radius));
      content: "";
      position: absolute;
      inset: 0;
      left: 50%;
      background-color: var(--siteheader-open-submenu-button-background-color, var(--color-rolling-stone-050));
      overflow: hidden;
      z-index: -1;
    }

    & .navigationItem {
      padding: 0.8rem 1.6rem;
      z-index: 0;

      &::before {
        content: "";
        opacity: 0;
        position: absolute;
        inset: 0 0.8rem;
        z-index: -1;
        background-color: var(--siteheader-open-submenu-button-background-color, var(--color-rolling-stone-100));
      }

      &:hover {
        &::before {
          opacity: 1;
          background-color: var(--siteheader-menu-item-hover-background-color, var(--color-rolling-stone-050));
        }
      }

      &::after {
        display: none;
      }
    }

    & .menuItem {
      border-radius: var(--border-radius);
    }
  }

  .navigationItem {
    justify-content: space-between;
    line-height: var(--line-height-s);
    padding: 0.8rem 0;
    position: relative;
    text-decoration: none;
    width: 100%;
    min-height: 3.6rem;
    color: var(--siteheader-navigation-item-color);
    font-size: var(--font-size-m);
    font-weight: var(--siteheader-navigation-item-font-weight, var(--font-weight-regular));
    display: flex;

    &::after {
      background: var(--siteheader-navigation-item-underline-color, var(--siteheader-navigation-item-color));
      bottom: 0;
      content: "";
      height: 0.1rem;
      left: 0;
      width: 100%;
      position: absolute;
      transition: transform 200ms ease-out;
      transform: scaleX(0);
      transform-origin: left;
    }

    &:hover::after,
    &:focus::after,
    &.isActive::after {
      transform: scaleX(1);
    }
  }

  .menuItem {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    background: transparent;
    border: 0;
  }

  .openSubmenuButton {
    --subemenu-button-width: 100%;

    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    cursor: pointer;
    z-index: 1;

    &::before {
      content: "";
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      z-index: -1;
      opacity: 0;
      background-color: var(--siteheader-open-submenu-button-background-color, var(--color-rolling-stone-050));
      border-radius: var(--border-radius);
      padding: calc(var(--font-size-3xl) * 0.5) calc(var(--subemenu-button-width) * 0.5 + 0.8rem);
    }

    &.isActive {
      &::before {
        opacity: 1;
      }

      & .arrowRight {
        transform: translateX(0.4rem);
      }
    }

    &::after {
      content: none;
    }
  }

  .nestedSubmenuButton {
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;
    cursor: pointer;
    z-index: 1;
    padding: 0.8rem 1.6rem;

    &::before {
      content: "";
      inset: 0 0.8rem;
      position: absolute;
      z-index: -1;
      opacity: 0;
      border-radius: var(--border-radius);
    }

    &.isActive {
      &::before {
        opacity: 1;
      }

      & .arrowRight {
        transform: translateX(0.4rem);
      }
    }

    &::after {
      content: none;
    }
  }
}

.searchContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--searchbar-height-s);
  background-color: var(--searchbar-background-color);
  color: var(--searchbar-text-color);
  z-index: calc(var(--z-index-site-header) + 1);
  display: flex;
  transform: translateY(-100%);
  transition: transform 200ms ease-out;
  border-bottom: 1px solid var(--searchbar-placeholder-color);

  @media (--viewport-menu) {
    height: var(--searchbar-height-l);
    border-bottom: 0;
  }

  &.isVisible {
    transform: translateY(0);
  }

  & .searchForm {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-bottom: 1px;

    & .underline {
      display: flex;
      width: 100%;
      align-items: center;
      height: var(--searchbar-input-height-s);

      @media (--viewport-menu) {
        border-bottom: 0.1rem solid var(--searchbar-placeholder-color);
        height: var(--searchbar-input-height-l);
      }
    }

    & .searchIcon {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 1.2rem;
    }

    & input {
      color: var(--searchbar-text-color);
      border: none;
      background-color: var(--searchbar-background-color);
      /* stylelint-disable-next-line */
      font-size: 16px;
      height: 100%;

      @media (--viewport-menu) {
        font-size: var(--font-size-l);
      }

      &:focus {
        outline: none;
      }

      &[type="search"] {
        flex: 1;
        padding-right: 1rem;

        &::-webkit-search-cancel-button {
          appearance: none;
          height: 2.4rem;
          width: 2.4rem;
          margin-left: 0.4em;
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='gray'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
          cursor: pointer;
        }
      }

      &[type="submit"] {
        cursor: pointer;
      }

      &::placeholder {
        color: var(--searchbar-placeholder-color);
        opacity: 1;
      }
    }
  }
}
