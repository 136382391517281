.form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  color: var(--newsletter-additional-data-form-color);
}

.fieldset {
  border: none;
  padding: 0;
}

.legend {
  padding: 0;
}

.inputContainer {
  display: flex;
  align-items: center;
  gap: 0.8rem;

  & input {
    margin: 0;
  }
}

.fieldError {
  color: var(--inputcontainer-error-color, var(--color-pomegranate-300));
  font-size: var(--font-size-s);
}


.error {
  color: var(--newsletter-form-error-color);
}