.button {
  background: none;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0.8rem;
  padding: 0 0 2rem;
  margin: 2rem 0 0.8rem 0.8rem;

  @media (--viewport-menu) {
    padding: 2rem;
    margin: 1.2rem 0 0.8rem;
  }

  & svg {
    height: var(--font-size-m);

    &:last-child {
      height: var(--font-size-xs);

      & * {
        fill: var(--color-white);
      }
    }
  }
}

.listContainer {
  position: relative;
}

.languageList {
  background-color: var(--language-toggle-langage-list-background-color, var(--color-rolling-stone-800));
  border-radius: var(--language-toggle-langage-list-border-radius, var(--border-radius-s));
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding-left: 0;
  position: absolute;
  display: none;

  @media (--viewport-menu-max) {
    min-width: 20rem;
  }

  &.isOpen {
    display: flex;
  }
}

.languageItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0;
  min-width: 17rem;
  padding: 0.8rem 1.2rem;

  @media (--viewport-menu-max) {
    min-height: 5.2rem;
  }

  &:hover,
  &:focus-within {
    background-color: var(--language-toggle-langage-list-item-hover-background-color, var(--color-rolling-stone-700));
  }

  &.isActive {
    background-color: var(--language-toggle-langage-list-item-hover-background-color, var(--color-rolling-stone-700));
  }
}

.languageButton {
  cursor: pointer;
  align-items: center;
  background: none;
  border: none;
  color: var(--language-toggle-button-color, var(--color-rolling-stone-050));
  display: flex;
  gap: 1.8rem;
  justify-content: space-between;
  text-decoration: none;
  width: 100%;

  @media (--viewport-menu-max) {
    font-size: var(--font-size-xl);
  }

  & svg {
    height: var(--font-size-m);
    margin-left: auto;

    &:last-child {
      flex: 0 0 auto;
      height: 1.8rem;
      width: 2.8rem;
    }
  }
}
