:root[site-id="vandeklok"] {
  --body-background-color: #fff;
  --header-box-shadow: none;
  --homepage-hero-background-color: var(--body-background-color);
  --default-picture-sourceset-border-radius: var(--border-radius-l);
  --heading-weight: 600;
  --heading-weight-strong: 600;
  --heading-strong-accent-color: var(--color-tangerine-600);
  --button-basic-rectangle-surface: var(--color-midnight-800);
  --button-basic-rectangle-surface-hover: var(--color-midnight-700);
  --button-basic-rectangle-color: #fff;
  --block-container-background-color-default: var(--body-background-color);
  --block-container-background-color-blue: var(--color-midnight-900);
  --block-container-surface-color-blue: var(--color-white);
  --block-container-background-color-white: var(--color-white);
  --backbutton-icon-color: var(--color-tangerine-600);
  --sharefooter-icon-hover-color: var(--color-tangerine-600);
  --job-item-container-background-color: var(--color-rolling-stone-050);
  --job-item-container-border-radius: 0.8rem;
  --filter-container-background-color: var(--color-white);
  --siteheader-navigation-background-color: var(--color-white);
  --header-background-color-active: var(--color-white);
  --siteheader-navigation-item-color: var(--color-midnight-800);
  --siteheader-menu-item-hover-background-color: var(--color-rolling-stone-100);
  --siteheader-submenu-background-color: var(--color-white);
  --siteheader-navigation-item-font-weight: var(--font-weight-bold);
  --image-badge-hover-background-color: var(--color-tangerine-600);
  --video-playbutton-background-color: var(--color-tangerine-600);
  --video-playbutton-border-rgb: 236 143 0;
  --sitefooter-social-icons-fill-color: var(--color-white);
  --sitefooter-title-color: var(--color-tangerine-600);
  --sitefooter-social-icons-path-fill-color: var(--color-midnight-800);
  --siteheader-submenu-color: var(--color-midnight-800);
  --siteheader-arrow-right-color: var(--color-tangerine-600);
  --siteheader-navigation-item-backbutton-icon-color: var(--color-tangerine-600);
  --siteheader-backbutton-color: var(--color-tangerine-600);
  --page-preview-item-container-border-radius: 0.8rem;
  --newsletter-form-input-placeholder-color: var(--color-rolling-stone-200);
  --newsletter-form-input-border-hover-color: var(--color-tangerine-600);
  --newsletter-form-error-color: var(--color-pomegranate-500);
  --contact-card-text-color: var(--color-white);
  --contact-card-icon-color: var(--contact-card-text-color);
  --contact-card-background-color: var(--color-tangerine-600);
  --input-focus-border-color: var(--color-midnight-800);
  --inputcontainer-description-font-weight: var(--font-weight-bold);
  --modal-header-icon-color: var(--color-tangerine-midnight-800);
  --preview-bar-background-color: var(--color-tangerine-600);
  --searchbar-background-color: var(--color-midnight-800);
  --searchbar-text-color: var(--color-white);
  --contentcard-subtitle-color: var(--color-tangerine-600);
  --contentcard-subtitle-font-size: var(--font-size-s);
  --contentcard-innerbox-background-color: var(--color-rolling-stone-050);
  --contentcard-innerbox-surface: var(--body-text-color);
  --hero-border-radius: var(--border-radius-l);
  --site-label-pill-color: var(--color-midnight-800);
  --contactperson-header-link-color: var(--color-tangerine-600);
  --call-to-action-content-color: var(--body-text-color);
  --contact-form-collection-contact-type-container-background: var(--color-rolling-stone-100);
  --contact-form-collection-contact-type-container-border-radius: var(--border-radius-l);
  --highlighted-content-background-color: var(--color-rolling-stone-100);
  --xl-image-card-text-color: var(--color-midnight-800);
  --richtext-quote-bar-color: var(--color-tangerine-600);
  --richtext-link-color-hover: var(--color-tangerine-600);
  --page-preview-item-location-color: var(--color-tangerine-600);
  --linklist-chevron-color: var(--color-tangerine-600);
  --block-container-surface-link-color-blue: var(--color-tangerine-600);
  --block-container-surface-link-color: var(--color-tangerine-600);
}

