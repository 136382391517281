.hasGreenUnderline {
  position: relative;
  padding: 0 0 2.4rem;
  margin: 0 0 2.4rem;

  &::after {
    background-color: var(--color-riptide-300);
    bottom: 0;
    content: "";
    height: 0.2rem;
    left: 0;
    position: absolute;
    width: 4rem;
  }
}
