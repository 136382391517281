.container {
  color: var(--color-white);
  padding: var(--site-footer-padding-top, 12rem) 0 1.6rem;
  position: relative;

  @media (--viewport-l) {
    padding: var(--site-footer-padding-top, 12rem) 0 8rem;
  }
}

.background {
  background-color: var(--color-rolling-stone-100);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;

  & > svg {
    height: 100%;
    position: absolute;
    right: 0;
    fill: var(--color-midnight-900);
    top: 0;
    transform: translateX(22%);
    width: auto;

    @media (--viewport-l) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.white {
    background-color: var(--color-white);
  }

  &.blue {
    background-color: var(--color-midnight-900);
  }

  &.lightGray {
    background-color: var(--color-rolling-stone-050);
  }

  &.darkGray {
    background-color: var(--color-rolling-stone-300);
  }

  &.dark {
    background-color: var(--color-rolling-stone-900);
  }
}

.firstFooter {
  display: flex;
  flex-direction: column;
  gap: 8rem;

  @media (--viewport-l) {
    flex-direction: row;
    gap: 3.2rem;
    min-height: 20rem;
    justify-content: space-around;
  }

  & > * {
    flex: 1;
  }
}

.payoff {
  font-size: var(--font-size-2xl);
  line-height: var(--line-height-m);

  @media (--viewport-l) {
    font-size: var(--font-size-4xl);
  }
}

.menusContainer {
  display: flex;
  gap: 1.6rem;

  & > * {
    width: 50%;
  }

  @media (--viewport-l) {
    gap: 3.2rem;
  }
}

.linkList {
  list-style-type: none;
  margin: 1.6rem 0 0;
  padding: 0;

  & > :not(:last-child) {
    margin-bottom: 1.6rem;
  }
}

.link {
  color: var(--footer-link-color, var(--color-white));
  font-size: var(--font-size-m);
  line-height: var(--line-height-l);
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.realFooter {
  display: flex;
  flex-direction: column;
  gap: 8rem;
  margin: 8rem 0 0;

  & svg {
    display: block;
    fill: var(--color-white);
    height: auto;
    max-width: 35rem;
  }

  @media (--viewport-l) {
    flex-direction: row;
    gap: 3.2rem;
    justify-content: space-between;
  }
}

.logo {
  display: flex;
  gap: 1.2rem;
  align-items: center;

  & svg {
    height: 9rem;
    width: auto;
    fill: var(--color-white);

    &:last-child {
      height: 2rem;
    }
  }
}

.socialIcons {
  display: flex;
  gap: var(--site-foot-social-icons-gap, 6rem);
  margin: 0 0 4rem;

  @media (--viewport-m) {
    margin: 0 0 var(--site-footer-social-icons-margin-bottom, 4rem);
  }

  & .socialIcon {
    background-color: var(--sitefooter-social-icons-fill-color, var(--color-white));
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--sitefooter-social-icons-border-radius, var(--border-radius-full));
  }

  & svg {
    height: 4rem;
    width: 4rem;

    & path {
      fill: var(--sitefooter-social-icons-path-fill-color, var(--color-midnight-900));
    }
  }
}

.siteFooterTitle {
  color: var(--sitefooter-title-color, inherit);
}

.siteFooterTitleFiller {
  height: 2.2rem; /* I eyeballed this */
}

.subMenu {
  display: flex;
  font-size: var(--font-size-xs);
  gap: 1.6rem;
  line-height: var(--line-height-m);
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: var(--footer-link-color, var(--color-white));

  & a {
    color: var(--footer-link-color, var(--color-white));
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  @media (--viewport-l) {
    gap: 2.4rem;
  }
}

.formSection {
  color: var(--footer-link-color, var(--color-white));
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.klokSignaal {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 8rem;

  @media (--viewport-m) {
    align-items: center;
    gap: 0;
    flex-direction: row;

    & > *:last-child {
      transform: translateY(var(--site-footer-bottom-container-last-child-translate-y, 0));
    }
  }
}
