:root {
  --color-white: #fff;
  --color-black: #000;
  --color-midnight-050: #e9f8fb;
  --color-midnight-100: #d8f3f8;
  --color-midnight-200: #cbedf6;
  --color-midnight-300: #9cdaed;
  --color-midnight-400: #6cbae4;
  --color-midnight-500: #3d97db;
  --color-midnight-600: #1c76c4;
  --color-midnight-700: #0a599e;
  --color-midnight-800: #002e5c;
  --color-midnight-900: #001d3a;
  --color-riptide-050: #f0fdf5;
  --color-riptide-100: #dcfce9;
  --color-riptide-200: #baf8d4;
  --color-riptide-300: #75eea9;
  --color-riptide-400: #49df8a;
  --color-riptide-500: #20c769;
  --color-riptide-600: #15a454;
  --color-riptide-700: #148144;
  --color-riptide-800: #156639;
  --color-riptide-900: #135431;
  --color-fruit-salad-500: #44934d;
  --color-fruit-salad-600: #388140;
  --color-persian-red-600: #c4332a;
  --color-persian-red-700: #b12c24;
  --color-pomegranate-050: #fef3f2;
  --color-pomegranate-100: #ffe3e1;
  --color-pomegranate-200: #ffccc8;
  --color-pomegranate-300: #ffa8a2;
  --color-pomegranate-400: #fc776d;
  --color-pomegranate-500: #f44336;
  --color-pomegranate-600: #e22d20;
  --color-pomegranate-700: #be2217;
  --color-pomegranate-800: #9d2017;
  --color-pomegranate-900: #82211a;
  --color-rolling-stone-050: #f6f6f7;
  --color-rolling-stone-100: #e1e4e6;
  --color-rolling-stone-200: #c3c9cc;
  --color-rolling-stone-300: #9ea5aa;
  --color-rolling-stone-400: #798188;
  --color-rolling-stone-500: #5f666d;
  --color-rolling-stone-600: #4b5156;
  --color-rolling-stone-700: #3e4347;
  --color-rolling-stone-800: #34383b;
  --color-rolling-stone-900: #242628;
  --color-tangerine-600: #ec8f00;
  --color-transparent: transparent;
  --font-size-xs: 1.2rem;
  --font-size-s: 1.4rem;
  --font-size-m: 1.6rem;
  --font-size-l: 1.8rem;
  --font-size-xl: 2rem;
  --font-size-2xl: 2.4rem;
  --font-size-3xl: 3rem;
  --font-size-4xl: 3.6rem;
  --font-size-5xl: 4.8rem;
  --font-size-6xl: 6rem;
  --font-size-7xl: 7.2rem;
  --font-size-8xl: 9.6rem;
  --font-size-9xl: 12.8rem;
  --font-family: "Fira Sans", sans-serif;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --line-height-xs: 1;
  --line-height-s: 1.1;
  --line-height-m: 1.3;
  --line-height-l: 1.5;
  --border-radius-s: 0.2rem;
  --border-radius: 0.4rem;
  --border-radius-l: 0.8rem;
  --border-radius-full: 9999999999999rem;
  --border-radius-round: 50%;
  --box-shadow: 0 0.2rem 0.4rem rgb(0 0 0 / 6%);
  --container-s: 53.4rem;
  --container-m: 80rem;
  --container-ml: 102.4rem;
  --container-l: 134.8rem;
  --container-xl: 153.6rem;
  --z-index-site-header: 99999;
  --z-index-cookie-banner: 9999999;
  --z-index-modal: 9999999999999;
  --siteheader-height-s: 6.2rem;
  --siteheader-height-l: 9.7rem;
  --spacing-s: 2.4rem;
  --max-width-padding-s: 1.6rem;
  --max-width-padding-m: 3.2rem;
  --body-background-color: var(--color-rolling-stone-100);
  --body-text-color: var(--color-midnight-800);
  --homepage-hero-background-color: var(--color-rolling-stone-050);
  --header-background-color: var(--color-white);
  --header-text-color: var(--body-text-color);
  --header-box-shadow: var(--box-shadow);
  --header-background-color-active: var(--color-midnight-900);
  --heading-weight: 400;
  --heading-weight-strong: 600;

  /* -----------------------------------Component-variables----------------------------------- */
  --siteheader-menu-items-padding: 1.6rem;
  --siteheader-submenu-item-padding: 1.6rem;
  --inputcontainer-error-color: var(--color-pomegranate-500);
  --form-error-color: var(--inputcontainer-error-color);
  --site-footer-padding-top: 4rem;
  --site-foot-social-icons-gap: 1.6rem;
  --site-footer-social-icons-margin-bottom: 8rem;
  --searchbar-height-s: 6rem;
  --searchbar-height-l: 12rem;
  --searchbar-input-height-s: 6rem;
  --searchbar-input-height-l: 5rem;
  --searchbar-placeholder-color: var(--color-rolling-stone-300);
  --searchbar-background-color: var(--body-background-color);
  --searchbar-text-color: var(--body-text-color);
}

/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import url("./global-heilijgers.css");
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import url("./global-novaform.css");
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import url("./global-vandeklok.css");
/* stylelint-disable-next-line no-invalid-position-at-import-rule */
@import url("./global-vanerk.css");

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgb(255 255 255 / 0%);
  -moz-osx-font-smoothing: grayscale;
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  font-size: 62.5%; /* 1rem = 10px */
  width: 100%;

  @media (--viewport-xxxl) {
    /* BD 2023-05-16: Made the whole site feel more screen-filling by request of client */
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    font-size: 67.5%;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: var(--body-background-color);
  color: var(--body-text-color);
  font-family: var(--font-family);
  font-size: var(--font-size-m);
  line-height: var(--line-height-l);
  margin: 0;
  padding-top: var(--siteheader-height-s);

  @media (--viewport-l) {
    padding-top: var(--siteheader-height-l);
  }
}

input,
textarea,
select,
button {
  font-family: var(--font-family);
}

.preLine, .preLine p {
  white-space: pre-line;
}

sup {
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  font-size: 0.6em;
}

/* This special .vh class is used to remove content visually,
without removing it from screen reader output. */

.vh {
  clip-path: inset(100%) !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  height: 1px !important;
  overflow: hidden !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}
