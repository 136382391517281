:root[site-id="novaform"],
:root[site-id="novaform-de"],
:root[site-id="novaform-pl"] {
  --body-background-color: var(--color-rolling-stone-900);
  --body-text-color: #fff;
  --default-border-radius: var(--border-radius-s);
  --default-picture-sourceset-border-radius: var(--default-border-radius);
  --homepage-hero-background-color: var(--body-background-color);
  --homepage-hero-text-color: var(--body-text-color);
  --homepage-hero-font-weight: regular;
  --heading-weight: 400;
  --heading-weight-strong: 600;
  --header-background-color: var(--body-background-color);
  --header-text-color: var(--body-text-color);
  --header-box-shadow: none;
  --button-basic-rectangle-surface: var(--color-persian-red-600);
  --button-basic-rectangle-surface-hover: var(--color-persian-red-700);
  --button-basic-rectangle-color: #fff;
  --button-basic-font-weight: 400;
  --button-basic-border-radius: var(--border-radius-s);
  --block-container-background-color-default: var(--body-background-color);
  --block-container-surface-color-default: var(--body-text-color);
  --block-container-background-color-blue: var(--color-white);
  --block-container-surface-color-blue: var(--color-rolling-stone-900);
  --block-container-background-color-white: var(--body-background-color);
  --block-container-surface-color-white: var(--color-white);
  --block-container-background-color-gray: var(--color-rolling-stone-100);
  --block-container-surface-color-gray: var(--color-rolling-stone-900);
  --block-container-background-color-light-gray: var(--color-rolling-stone-050);
  --block-container-surface-color-light-gray: var(--color-rolling-stone-900);
  --block-container-surface-link-color: var(--color-persian-red-600);
  --block-container-surface-link-color-blue: var(--color-persian-red-600);
  --link-with-chevron-color: var(--color-persian-red-600);
  --backlink-color: var(--color-persian-red-600);
  --backbutton-icon-color: var(--color-white);
  --sharefooter-container-background-color: var(--color-rolling-stone-900);
  --sharefooter-container-color: var(--color-white);
  --sharefooter-icon-color: var(--color-white);
  --sharefooter-icon-hover-color: var(--color-persian-red-600);
  --job-grid-background-color: var(--color-white);
  --job-item-container-background-color: var(--color-rolling-stone-050);
  --job-grid-color: var(--color-rolling-stone-900);
  --job-item-container-border-radius: 0.2rem;
  --job-feature-value-color: var(--color-rolling-stone-600);
  --job-hero-border-radius: var(--border-radius-s);
  --filter-container-background-color: var(--color-rolling-stone-050);
  --filter-container-label-color: var(--color-rolling-stone-900);
  --filters-button-inner-color: var(--color-rolling-stone-900);
  --range-slider-inner-rail-background: var(--color-persian-red-700);
  --range-slider-control-background: var(--color-rolling-stone-900);
  --siteheader-navigation-background-color: var(--color-rolling-stone-900);
  --header-background-color-active: var(--color-rolling-stone-900);
  --siteheader-navigation-item-color: var(--color-white);
  --siteheader-submenu-color: var(--color-white);
  --siteheader-backbutton-color: var(--color-persian-red-600);
  --siteheader-submenu-level1-background-color: var(--color-rolling-stone-900);
  --siteheader-submenu-level2-background-color: var(--color-rolling-stone-800);
  --siteheader-open-submenu-button-background-color: var(--color-rolling-stone-800);
  --siteheader-navigation-item-underline-color: var(--color-persian-red-600);
  --siteheader-navigation-item-backbutton-icon-color: var(--siteheader-backbutton-color);
  --siteheader-submenu-background-color: var(--color-rolling-stone-900);
  --siteheader-menu-item-hover-background-color: var(--color-rolling-stone-900);
  --siteheader-menu-button-svg-stroke-color: var(--color-rolling-stone-900);
  --image-badge-hover-background-color: var(--color-rolling-stone-900);
  --image-badge-hover-color: var(--color-persian-red-600);
  --video-playbutton-background-color: var(--color-persian-red-600);
  --video-playbutton-border-rgb: 226 45 32;
  --sitefooter-title-color: var(--color-white);
  --sitefooter-social-icons-border-radius: var(--border-radius);
  --sitefooter-social-icons-path-fill-color: var(--color-rolling-stone-900);
  --page-preview-item-container-border-radius: var(--border-radius-s);
  --richttext-color-dark: var(--color-rolling-stone-900);
  --richttext-color-dark-hover: var(--color-white);
  --newsletter-subscription-tile-container-background-color: var(--color-rolling-stone-900);
  --newsletter-subscription-tile-container-color: var(--color-rolling-stone-900);
  --newspage-container-background-color: var(--color-rolling-stone-050);
  --newpage-general-styles-container-color: var(--color-rolling-stone-900);
  --newsletter-form-input-placeholder-color: var(--color-rolling-stone-100);
  --newsletter-form-input-border-hover-color: var(--color-persian-red-600);
  --newsletter-form-input-border-color: var(--color-rolling-stone-050);
  --newsletter-form-error-color: var(--color-persian-red-600);
  --cookiemodal-outline-button-color: var(--color-rolling-stone-900);
  --modal-title-color: var(--color-rolling-stone-900);
  --project-filters-color: var(--color-rolling-stone-900);
  --contact-card-text-color: var(--color-rolling-stone-050);
  --contact-card-icon-color: var(--color-rolling-stone-050);
  --contact-card-container-border-radius: var(--border-radius-m);
  --contact-card-background-color: var(--color-rolling-stone-900);
  --contentcard-innerbox-background-color: var(--color-rolling-stone-050);
  --contentcard-innerbox-surface: var(--color-rolling-stone-900);
  --contentcard-subtitle-color: var(--color-persian-red-600);
  --input-focus-border-color: var(--color-rolling-stone-900);
  --modal-content-color: var(--color-rolling-stone-900);
  --preview-bar-background-color: var(--color-rolling-stone-700);
  --color-projecthero-submeta: var(--body-text-color);
  --hero-border-radius: var(--border-radius-s);
  --site-label-pill-color: var(--color-rolling-stone-900);
  --call-to-action-content-color: var(--color-rolling-stone-900);
  --blog-hero-content-color: var(--color-rolling-stone-900);
  --contactperson-header-border-color: var(--color-rolling-stone-700);
  --contactperson-header-link-color: var(--color-persian-red-600);
  --vertical-carousel-button-color-dark: var(--color-rolling-stone-900);
  --vertical-carousel-font-weight-bold: var(--font-weight-regular);
  --xl-image-card-text-color: var(--color-rolling-stone-900);
  --richtext-quote-bar-color: var(--color-persian-red-600);
  --page-preview-item-location-color: var(--color-persian-red-600);
  --linklist-chevron-color: var(--color-persian-red-600);
}
