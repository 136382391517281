.input {
  background: var(--color-white);
  border-radius: var(--input-border-radius, var(--border-radius-s));
  border: 0.1rem solid var(--color-rolling-stone-300);
  color: var(--color-rolling-stone-400);
  font-size: var(--font-size-s);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-l);
  outline: none;
  padding: 1rem 1.2rem;
  width: 100%;

  &:focus {
    border-color: var(--input-focus-border-color, var(--color-riptide-300));
  }
}

.error {
  border-color: var(--inputcontainer-error-color, var(--color-pomegranate-300));
}
