.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-index-modal);
}

.overlay {
  background-color: rgb(0 0 0 / 40%);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.content {
  -ms-overflow-style: none;
  background-color: var(--modal-content-background-color, var(--color-white));
  border-radius: var(--default-border-radius, var(--border-radius-l));
  left: 50%;
  color: var(--modal-content-color, inherit);
  max-height: calc(100% - 4rem);
  max-width: var(--container-m);
  overflow-y: scroll;
  padding: 2rem;
  position: relative;
  scrollbar-width: none;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 4rem);

  & h2,
  & h3,
  & h4 {
    color: var(--modal-content-color, inherit);
  }

  @media (--viewport-m) {
    padding: 4rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.smallPadding {
    @media (--viewport-m) {
      padding: 2.4rem;
    }
  }

  &.large {
    max-width: var(--container-ml);
  }
}

.header {
  align-items: center;
  color: var(--modal-title-color);
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.6rem;

  & svg {
    width: 50%;
  }

  &.noMargin {
    margin-bottom: 0;
  }
}

.closeButton {
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  height: 4rem;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 4rem;

  & svg {
    color: var(--color-midnight-900);
    height: 2.4rem;
    width: 2.4rem;
  }

  &.floating {
    position: absolute;
    right: 2rem;
    top: 2rem;
    background: var(--color-rolling-stone-050);
    transition: background 0.2s ease-in-out;
    z-index: 1;

    &:hover {
      background: var(--color-rolling-stone-100);
    }

    @media (--viewport-m) {
      right: 4rem;
      top: 4rem;
    }
  }
}

.container[class*="vanerk"], .container[class*="novaform"]{
  .header svg path {
    fill: var(--modal-header-icon-color, currentcolor);

  } 
}