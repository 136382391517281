.container {
  &.darkText {
    color: var(--richttext-color-dark);
  }

  &.lightText {
    color: var(--richttext-color-light);
  }

  &.colorWhite {
    color: var(--color-white);
  }

  & h1,
  & h2,
  & h3,
  & h4 {
    font-weight: var(--heading-weight);
    line-height: var(--line-height-s);
    margin: 0;
    word-wrap: break-word;
  }

  & h1,
  & h2,
  & h4 {
    & strong {
      font-weight: var(--heading-weight-strong, var(--font-weight-bold));
      color: var(--heading-strong-accent-color);
    }
  }

  & h1 {
    font-size: var(--font-size-4xl);

    @media (--viewport-m) {
      font-size: var(--font-size-6xl);
    }
  }

  & h2 {
    font-size: var(--font-size-2xl);

    @media (--viewport-m) {
      font-size: var(--font-size-4xl);
    }
  }

  & h3 {
    font-size: var(--font-size-l);

    @media (--viewport-m) {
      font-size: var(--font-size-xl);
    }
  }

  & h4 {
    font-size: var(--font-size-m);

    @media (--viewport-m) {
      font-size: var(--font-size-l);
    }
  }

  & h1:not(:last-child),
  & h2:not(:last-child),
  & h3:not(:last-child),
  & h4:not(:last-child) {
    margin-bottom: 0.5em;
  }

  & p {
    font-size: var(--font-size-m);
    line-height: var(--line-height-l);
    margin: 0;

    &:not(:last-child) {
      margin: 0 0 1.2em;
    }

    &:global(.large) {
      font-size: var(--font-size-l);
    }

    & + h1,
    & + h2,
    & + h3,
    & + h4 {
      margin-top: 1em;
    }
  }

  & a {
    /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
    color: var(--richtext-link-color, inherit);

    &:hover {
      /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
      color: var(--richtext-link-color-hover, inherit);
    }
  }

  & ul,
  & ol {
    font-size: var(--font-size-m);
    line-height: var(--line-height-l);
    margin: 0;
  }

  & ol:not(:last-child),
  & ul:not(:last-child) {
    margin: 0 0 1.2em;
  }

  &.large {
    & p,
    & ul,
    & ol {
      font-size: var(--font-size-l);
    }

    & h3,
    & h4 {
      font-weight: var(--heading-weight);
    }
  }

  @media (--viewport-m) {
    &.extraLarge {
      & p,
      & ul,
      & ol {
        font-size: var(--font-size-l);
      }

      & h3,
      & h4 {
        font-weight: var(--heading-weight);
      }
    }
  }

  @media (--viewport-l) {
    &.extraLarge {
      & p,
      & ul,
      & ol {
        font-size: var(--font-size-2xl);
      }
    }
  }

  & blockquote {
    border-left: solid 0.2rem var(--richtext-quote-bar-color, var(--color-riptide-300));
    padding-left: 0.8rem;
    margin-left: 0;
    font-size: var(--font-size-xl);
    font-style: italic;
  }
}
