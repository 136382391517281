:root[site-id="heilijgers"] {
  --body-background-color: #fff;
  --default-border-radius: 0.4rem;
  --default-picture-sourceset-border-radius: var(--default-border-radius);
  --default-grid-gap: 0.8rem;
  --heading-weight: 600;
  --heading-weight-strong: 600;
  --button-basic-rectangle-surface: var(--color-fruit-salad-500);
  --button-basic-rectangle-surface-hover: var(--color-fruit-salad-600);
  --button-basic-rectangle-color: #fff;
  --button-basic-border-radius: 0.4rem;
  --block-container-background-color-default: var(--body-background-color);
  --block-container-background-color-blue: var(--color-midnight-900);
  --block-container-surface-color-blue: var(--color-white);
  --block-container-background-color-white: var(--color-white);
  --link-with-chevron-color: var(--color-fruit-salad-600);
  --backlink-color: var(--color-rolling-stone-900);
  --backbutton-icon-color: var(--color-fruit-salad-600);
  --sharefooter-container-background-color: var(--color-rolling-stone-300);
  --sharefooter-container-color: var(--color-rolling-stone-900);
  --sharefooter-icon-color: var(--color-rolling-stone-900);
  --sharefooter-icon-hover-color: var(--color-rolling-stone-050);
  --job-item-container-border-radius: 0.4rem;
  --filter-container-background-color: var(--color-white);
  --range-slider-inner-rail-background: var(--color-fruit-salad-500);
  --range-slider-control-background: var(--color-fruit-salad-600);
  --job-item-container-background-color: var(--color-rolling-stone-050);
  --siteheader-navigation-background-color: var(--color-fruit-salad-500);
  --header-background-color-active: var(--color-fruit-salad-500);
  --siteheader-navigation-item-color: var(--color-rolling-stone-900);
  --siteheader-menu-item-hover-background-color: var(--color-rolling-stone-100);
  --siteheader-submenu-background-color: var(--color-fruit-salad-500);
  --siteheader-submenu-color: var(--color-rolling-stone-900);
  --siteheader-backbutton-color: var(--color-rolling-stone-900);
  --siteheader-navigation-item-backbutton-icon-color: var(--color-rolling-stone-900);
  --siteheader-arrow-right-color: var(--color-rolling-stone-900);
  --siteheader-navigation-item-font-weight: var(--font-weight-bold);
  --image-badge-hover-background-color: var(--color-fruit-salad-500);
  --image-badge-hover-color: var(--color-white);
  --video-playbutton-background-color: var(--color-fruit-salad-500);
  --video-playbutton-border-rgb: 68 147 77;
  --contentcard-subtitle-color: var(--color-fruit-salad-600);
  --contentcard-innerbox-background-color: var(--color-rolling-stone-050);
  --contentcard-innerbox-surface: var(--body-text-color);
  --contentcard-contentholder-padding-right: 2.4rem;
  --sitefooter-social-icons-fill-color: var(--color-white);
  --sitefooter-title-color: var(--color-rolling-stone-900);
  --footer-link-color: var(--color-rolling-stone-900);
  --sitefooter-social-icons-border-radius: var(--border-radius);
  --sitefooter-social-icons-path-fill-color: var(--color-rolling-stone-900);
  --page-preview-item-container-border-radius: var(--border-radius);
  --newsletter-subscription-tile-container-background-color: var(--color-rolling-stone-050);
  --newsletter-subscription-tile-container-color: var(--color-rolling-stone-900);
  --newsletter-form-input-border-color: var(--color-rolling-stone-100);
  --newsletter-form-input-placeholder-color: var(--color-rolling-stone-100);
  --newsletter-form-input-border-hover-color: var(--color-fruit-salad-500);
  --newsletter-form-error-color: var(--color-pomegranate-500);
  --contact-card-text-color: var(--color-white);
  --contact-card-icon-color: var(--contact-card-text-color);
  --contact-card-background-color: var(--color-fruit-salad-500);
  --input-focus-border-color: var(--color-fruit-salad-500);
  --modal-header-icon-color: var(--color-fruit-salad-500);
  --preview-bar-background-color: var(--color-rolling-stone-300);
  --contact-page-body-background-color: var(--color-rolling-stone-050);
  --hero-border-radius: 0;
  --contactperson-header-link-color: var(--color-fruit-salad-500);
  --call-to-action-content-color: var(--body-text-color);
  --vertical-carousel-button-color-dark: var(--color-rolling-stone-900);
  --richtext-quote-bar-color: var(--color-fruit-salad-500);
  --richtext-link-color-hover: var(--color-fruit-salad-500);
  --page-preview-item-location-color: var(--color-fruit-salad-500);
  --linklist-chevron-color: var(--color-fruit-salad-500);
  --block-container-surface-link-color-blue: var(--color-fruit-salad-500);
  --block-container-surface-link-color: var(--color-fruit-salad-500);
}
