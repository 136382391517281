.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--siteheader-height-s, 0px));

  @media (--viewport-l) {
    min-height: calc(100vh - var(--siteheader-height-l, 0px));
  }
}

.main {
  display: flex;
  flex-direction: column;
  flex: 1;

  & > *:nth-last-child(1) {
    flex: 1;
  }
}
