.container {
  background-color: var(--newsletter-subscription-tile-container-background-color, var(--color-midnight-900));
  border-radius: var(--border-radius-l);
  color: var(--newsletter-subscription-tile-container-color, var(--color-white));
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--newsletter-subscription-tile-container-padding, 0);
  position: relative;
  overflow: hidden;
  width: 100%;

  & h3 {
    font-size: var(--font-size-xl);

    @media (--viewport-m) {
      font-size: var(--font-size-2xl);
    }
  }

  & .hexagon svg {
    position: absolute;
    z-index: 0;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  position: relative;
  width: 100%;
  z-index: 2;
}